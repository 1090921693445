<template>
  <el-main>
    <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus" @click="addMarketing">添加营销</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="营销名称：">
        <el-input size="small" v-model="name" placeholder="请输入营销名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="18px">
        <el-button type="primary" @click="search" size="small">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="营销名称" align="center"></el-table-column>
      <el-table-column prop="state" label="营销功能说明" align="center"></el-table-column>
      <el-table-column prop="value" label="营销二维码图" align="center">
        <template v-slot="{ row }">
          <el-image v-if="row.type == 2 || row.type == 3" style="width: 50px; height: 50px" :src="row.value"></el-image>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="小程序APPID" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? row.value : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="是否为会员权益" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_rights" :active-value="1" :inactive-value="0" @change="editChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加/编辑营销" :visible.sync="showAdd" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="130px">
        <el-form-item class="item" label="营销名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="跳转类型：">
          <el-radio-group v-model="form.type" @change="getType">
            <el-radio :label="1">点击跳转</el-radio>
            <el-radio :label="2">普通二维码</el-radio>
            <el-radio :label="3">小程序码</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type == 2 || form.type == 3" label="二维码图 ：" prop="code">
          <ReadyUploadSource :path="form.code" @getSource="getCodeImg" @removeThis="() => (form.code = '')"></ReadyUploadSource>
          <p style="margin-top: 10px">建议图片尺寸800*800px，大小不超过500kb</p>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="小程序APPID：" prop="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="营销功能说明：" prop="state">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="form.state"></el-input>
        </el-form-item>
        <el-form-item class="item" label="是否为会员权益：">
          <el-switch v-model="form.is_rights" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Paging from '@/components/paging';

export default {
  components: {
    ReadyUploadSource,
    Paging,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      total_number: 0,
      moveClass_name: '',
      showAdd: !1,
      list: [],
      is_show: 0,
      name: '',
      showOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '否',
        },
        {
          value: 2,
          label: '是',
        },
      ],
      form: {
        name: '',
        type: 1,
        value: '',
        state: '',
        appid: '',
        code: '',
        is_rights: 1,
      },
      rules: {
        name: { required: true, message: '请填写营销名称', trigger: 'blur' },
        appid: {
          required: true,
          message: '请填写小程序APPID',
          trigger: 'blur',
        },
        code: {
          required: true,
          message: '请添加二维码图',
          trigger: 'blur',
        },
        state: { required: true, message: '请填写营销功能说明', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.name = '';
      this.page = 1;
      this.getList();
    },
    getCodeImg(val) {
      this.form.code = val.path;
    },
    addMarketing() {
      this.showAdd = !0;
      this.form = {
        name: '',
        type: 1,
        value: '',
        state: '',
        appid: '',
        code: '',
        is_rights: 1,
      };
    },
    edit(row) {
      let obj = Object.assign({}, row);
      if (obj.type == 1) {
        obj.appid = obj.value;
      } else {
        obj.code = obj.value;
      }
      delete obj.create_time;
      delete obj.update_time;
      this.form = obj;
      this.showAdd = !0;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let form = Object.assign({}, this.form);
          if (form.type == 1) {
            form.value = form.appid;
          } else {
            form.value = form.code;
          }
          delete form.appid;
          delete form.code;
          let url = form.id ? this.$api.samecity.editMarketing : this.$api.samecity.addMarketing;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              this.showAdd = !1;
              this.getList();
              this.$message.success(`${form.id ? '编辑成功' : '添加成功'}`);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios
        .post(this.$api.samecity.marketingList, {
          page: this.page,
          rows: this.rows,
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    editChange(row) {
      this.$axios
        .post(this.$api.samecity.editIsRights, {
          id: row.id,
          is_rights: row.is_rights,
        })
        .then(res => {
          if (res.code == 0) {
            console.log('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //删除营销
    remove(row) {
      this.$confirm('确认删除此营销？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.delMarketing, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    flex: 1;
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  .edit {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
    i {
      font-size: 17px;
    }
  }
}
</style>
